<template>
    <div class="sim-pagination-info">
        <i18n-t :keypath="t" scope="global">
            <template #range>
                {{ `${range.start}-${range.end}` }}
            </template>
            <template #total>
                {{ total }}
            </template>
        </i18n-t>
    </div>
</template>

<script lang="ts" setup>
import type { ApiResponse } from '@composable-api/api.response'

const {
    pagination,
    t,
} = defineProps<{
    pagination: InstanceType<typeof ProductFiltersPaginationModel> | ReturnType<ApiResponse<any>['getMeta']> | null | undefined
    t: string
}>()

const range = computed(() => {
    function calculateRange(page: number, perPage: number, itemCount: number) {
        if (itemCount === 0) {
            return { start: 0, end: 0 }
        }
        const start = (page - 1) * perPage + 1
        const end = Math.min(page * perPage, itemCount)
        return { start, end }
    }

    if (pagination instanceof ProductFiltersPaginationModel) {
        return calculateRange(pagination.page ?? 0, pagination.perPage ?? 0, pagination.itemsCount ?? 0)
    } else if (pagination && pagination.records !== undefined) {
        return calculateRange(pagination.page, pagination.per_page, pagination.records)
    }

    return { start: 0, end: 0 }
})

const total = computed(() => {
    if (pagination instanceof ProductFiltersPaginationModel) {
        return pagination.itemsCount
    } else {
        return pagination?.records
    }
})

</script>

<style lang="scss" scoped>

</style>
